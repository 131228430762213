import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { server,cookies,local,parrot_key } from './components/environ';
import { init } from './components/parrot-js/parrot';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import {blue,pink,grey} from '@material-ui/core/colors/';


import StickyFooter   from  './components/vendor/stickyfooter';
import ErrorBoundary   from  './ErrorBoundary';
import {theme} from './components/theme'

import Privacy   from  './components/vendor/signin/privacy';
import Terms   from  './components/vendor/signin/terms';
import Web   from  './components/vendor/Web';




function App() {
  
  init(parrot_key);
  return (
    
    <ThemeProvider theme={theme}>
    <Router>
    <Suspense fallback={<div></div>}>
    <Switch>


        <Route  exact path="/" component={Web} />
        <ErrorBoundary webaddress={window.location.href}>
        <Route path="/terms" component={() => ( <Terms 
            company_name="Webber Solutions Limited" 
            website="webberapps.com" 
            city="London" 
            type="business" 
            reg_add="27, Old Gloucester Street, LONDON, WC1N 3AX, UNITED KINGDOM." /> ) } />
        <Route path="/privacy" component={() => ( <Privacy 
            company_name="Webber Solutions Limited" 
            website="webberapps.com" 
            city="London" 
            type="business" 
            reg_add="27, Old Gloucester Street, LONDON, WC1N 3AX, UNITED KINGDOM." /> ) } />
        </ErrorBoundary>
           
    </Switch>
    </Suspense>
    </Router>
    </ThemeProvider>
  );
}

export default App


//ReactDOM.render(<App />, document.querySelector('#app'));


