import React from 'react';
import { Component,Fragment } from 'react'

import { useTheme,createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import MuiAlert from '@material-ui/lab/Alert';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';

import { server,cookies,site_name,domain_name,subscriptionPlans,signUpContent,help_email,company_name,contact_number } from '../environ';
import {translator} from  '../parrot-js/parrot';
import { con,cur_con,lang,getLanguageName,getCountryName,getCurrencyName,getCurrencySymbol,getCountryList,getLanguageList } from '../countrycurrencies';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SocialIcon } from 'react-social-icons';

import Link from '@material-ui/core/Link';
import $ from 'jquery';


let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    h1: {
      fontWeight: 700,
      fontSize: 40,
    },
    h2: {
      fontWeight: 400,
      fontSize: 33.6,
      color: 'rgb(0,0,0)'
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 16,
      fontWeight: 400,
      color: 'rgb(0,0,0)',
      letterSpacing : '0.5px'
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
      //  backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const useStyles = theme => ({

  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  aroot: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  blockSection: {
    backgroundColor : "#cf6b4c"
  }
});



function Copyright(props) {
return (
      <Grid container style={{ marginTop : "8px"}} spacing={1} justify="center">
      
      <Grid item align="center" >
      <Typography variant="body2" style={{ color: "black" }} align="center">
      <Link color="inherit"  href={window.location.origin}  children={' © ' + company_name + " 2020. All Rights Reserved."}/>
      </Typography>
      </Grid>

      </Grid>   
    
  );
}

function SimpleDialog(props) {
  return (
    <Dialog open={props.open} onClose={e =>  props.backFromCancel(e)} aria-labelledby="form-dialog-title">
    <DialogContent>
    <DialogContentText>
     {props.text}
    </DialogContentText>
    </DialogContent>
    <DialogActions>
    <Button onClick={e => props.backFromCancel(e)} color="secondary">
      Cancel
    </Button>
    <Button onClick={e => props.backFromOK(e)} color="primary">
      OK
    </Button>
    </DialogActions>
  </Dialog>
    );
}


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const action = (
  <div>
  <Button color="secondary" size="small">
    Accept
  </Button>
   <Button color="secondary" size="small">
    Accept2
  </Button>
  </div>
);

const messageCookies = "We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and to analyze our website traffic. By browsing our website, you consent to our use of cookies and other tracking technologies."

class WebW extends Component {

  constructor (props) {
      super(props);
      this.state = { 
                     drawerOpen : false,
                     cbOpen : false,
                     language_code : 'en',
                     country_code : 'US',
                     currency_code : 'USD',
                     refresh : true,
                     subscriptionPlans : [],
                     lpOpen : false,
                     cpOpen : false
                   };
  }

  componentDidMount(){ 
    //listner on fetched data and do a refresh
    document.getElementById("root").addEventListener('parrot_data',e => this.setState({refresh:!this.state.refresh}),false);



    this.setLocale();

    if (cookies.get('/user/accept_cookies') != undefined)
     this.setState({cbOpen : JSON.parse(cookies.get('/user/accept_cookies')) })
    else
      setTimeout(() => { this.setState({cbOpen : true}) },3000)
//    this.getWebsiteData();
  }

  componentDidUpdate(prevProps,prevState) {

    if( (prevState.language_code != this.state.language_code) || this.state.subscriptionPlans.length == 0 || (prevState.country_code != this.state.country_code)  ) 
    {  
      this.getWebsiteData();
       cookies.set('/user/language_code',this.state.language_code, { path: '/' });
       cookies.set('/user/country_code',this.state.country_code, { path: '/' });
       cookies.set('/user/currency_code',this.state.currency_code, { path: '/' });
    }

    if (prevState.cbOpen != this.state.cbOpen)
      cookies.set('/user/accept_cookies',this.state.cbOpen, { path: '/' });
  }

  setLocale = () => {

    if( cookies.get('/user/language_code') != undefined)
     this.setState({language_code : cookies.get('/user/language_code'), country_code : cookies.get('/user/country_code'), currency_code : cookies.get('/user/currency_code')});
    else
    {
      var language = 'en', country = 'US', currency = 'USD';
      const getLanguage = () => navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';
      //console.log(describeIETFLanguageTag('zh-Hans'))

      var system_data = {};
      system_data.getLanguage = getLanguage();

      this.setState({system_data : system_data});

      var browserLanguage = getLanguage().split('-')[0];
      var browserCountry = getLanguage().split('-')[1];

      var googleFilteredLanguage = false;

      if (browserLanguage != undefined) 
       getLanguageList().forEach(x => {
        if (x.code == browserLanguage )
          googleFilteredLanguage = true;
        })
    
      if (googleFilteredLanguage && this.props.lc == undefined)
      { 
        language =  browserLanguage;
        this.setState({language_code : language});
      }

/*      if (browserCountry != undefined)
      { 
        country = browserCountry;
        this.setState({country_code : country})
      }
      console.log(browserCountry,browserLanguage)
*/ 
      var url = 'https://api.ipify.org/?format=json' ;
      fetch(url)
      .then(res=>res.json())
      .then(res => { 
      
         var add = res.ip; 
      
         fetch('https://ip2c.org/' + add)
         .then(res=>res.text())
         .then(res => { 

          if(res.split(';')[1] != undefined)
            country = res.split(';')[1];

          system_data.country = country;
          this.setState({system_data : system_data});

          var y = cur_con.find(x => x.country == country);

          if ( y != undefined )
            currency = y.currency; 

          this.setState({country_code : country, currency_code : currency});
        })        
    })
    }
  }


  getWebsiteData = () => {
    var data = {};
    data.lc = this.state.language_code;
    data.country_code = this.state.country_code;
    var options = {   method: 'POST',
                      headers: {'Content-Type': 'application/json'},
                      body: JSON.stringify(data) 
                  };
    var subscriptionPlans = [];
    let url = server + '/subscriptions/list'
    fetch(url,options)
    .then(response => response.json())
    .then(jsondata => {
      if(jsondata.status == "success")
        { jsondata.data.forEach( x =>  { x.plan_object.p_id = x.p_id; subscriptionPlans.push(x.plan_object);  }  );  
          this.setState({subscriptionPlans : subscriptionPlans })
        }
      else console.log("Some Error Occured") 
    })
  }



   lpClose = (e) => {
      this.setState({lpOpen : false})
    }
    cpClose = (e) => {
      this.setState({cpOpen : false})
    }

    lpSelect = (e,item) => {
      this.setState({language_code : item.code});
      cookies.set('/user/language_code',item.code, { path: '/' });
      this.lpClose();      
    }


    cpSelect = (e,item) => {

      var currency_code;
      var y = cur_con.find(x => x.country == item[0])
      if (y == undefined)
        currency_code = this.state.currency_code;
      else 
        currency_code = y.currency;

      this.setState({country_code : item[0], currency_code : currency_code});
      cookies.set('/user/country_code',item[0], { path: '/' });
      cookies.set('/user/currency_code',currency_code, { path: '/' });
      this.cpClose();
    }

  LanguagePicker = () => {   
  return (
    <Dialog onClose={e => this.lpClose(e)} aria-labelledby="simple-dialog-title" open={this.state.lpOpen}>
      <List dense  align="center">
        {getLanguageList().map((x) => (
          <ListItem  button onClick={e => this.lpSelect(e,x)} key={x.code}>
            <ListItemText children={<Typography variant="body2" children={x.name} />}  /> 
          </ListItem>
        ))}
      </List>
    </Dialog>
  );

  }

 CountryPicker = () => {  

  return (
    <Dialog onClose={e => this.cpClose(e)} aria-labelledby="simple-dialog-title" open={this.state.cpOpen}>
      <List dense align="center">
        { getCountryList().map( (x) => ( 
          <ListItem button onClick={e => this.cpSelect(e,x)} key={x[0]}>
            <ListItemText  children={<Typography variant="body2" children={x[1]} />}  />  
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
  }


  render() {
  const { classes } = this.props;
  var lc = (this.state.language_code == undefined) ? 'en' : this.state.language_code;
      lc = (this.props.lc == undefined) ? lc : this.props.lc;

  var p = new translator(lc,'/w/web','returnNothing');

  var lang_name = lang.find(x => x.code == this.state.language_code );
      lang_name = (lang_name != undefined) ? lang_name.name : "English";
  var country_name = (con[this.state.country_code] != undefined) ? con[this.state.country_code] : "USA";

   const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
   const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

   return(
    <ThemeProvider theme={theme}>
    <div >

    <Container disableGutters maxWidth="false" >


    <div className={classes.aroot}>

      <AppBar position="fixed" variant="outlined" style= {{ backgroundColor :"white" }} elevation={0}>
        <Toolbar >
        
          <h2 style={{ color: "#333B69" }} className={classes.title} onClick={ e => {/*window.location.href = window.location.origin  */} } >  {site_name} </h2>
         
        </Toolbar>
      </AppBar>
       <Drawer variant="temporary" open={this.state.drawerOpen} onClick={e => this.setState({drawerOpen: false})} style={{ width : 100 }} anchor="right">
        <Box m={1} />
        <List >

            <ListItem button   >
            <Typography variant="h4" style={{ color: "#333B69", margin:"10px"}} onClick={ e =>  this.setState({ drawerOpen : false}) } >
              <Link color="inherit" underline="none" href="#features" > Features </Link> 
            </Typography>       
            </ListItem>

            <ListItem button    >
              <Typography  variant="h4" style={{ color: "#333B69", margin:"10px"}} onClick={ e =>  this.setState({ drawerOpen : false}) } >
              <Link color="inherit" underline="none" href="#pricing" > Pricing </Link> 
              </Typography>           
            </ListItem>
            <ListItem button>
              <Button color="secondary" variant="contained" size="large" onClick={ e =>  (window.location = window.origin +   '/signin?signup=true' )  }  > <h4  style={{ "font-weight": "bold", "textTransform": "none", color: "white", marginTop : 0, marginBottom : 0 }}  > 
              {p.t('Try for free','/topbar/loginButton/v7')}  </h4> </Button>
            </ListItem>
        </List>
      </Drawer>
    </div>
    
    <Grid container justify="center" alignContent="center" alignItems="center" 
    style={{ backgroundBlendMode : "lighten",backgroundColor: 'rgba(237, 246, 252,0.8)', minHeight: '95vh' }} xs={12} >  
      <Hidden smUp children={<Box m={8} />} />


      <Grid container style={{ margin: ("10vh","10vw") }} align={"center"} >
          
          <Grid xs={12}  alignContent="center" >
          
            <Grid xs="12"> 
              <Typography variant="h1">
                {'Business apps in a day' }
              </Typography>
            </Grid>

            <Grid xs="12" style={{ marginTop: "20px", marginBottom: "20px"}}> 
              <Typography variant="h3">
                {'Supercharge your workflows'}
              </Typography>
            </Grid>

          </Grid>

           <Grid xs={12} alignContent="center" style={{ marginTop: "8vh"}} >
              <Grid container spacing={3} direction="column" >
             { signUpContent(p).map( (row,index) => {
                return(
                    <Grid item align="center">
                    <Typography style={{marginBottom : "4px"}} variant={'subtitle1'} children={row.title}/> 
                    <Typography style={{color: "#222222"}} variant={'body2'} children={row.content}/>
                   </Grid>
                
                );
            })}
             </Grid>

          </Grid>
          
          <Grid xs={12} style={{ marginTop: "10vh", marginBottom: "10vh"}} align={"center"} >
          <Grid container spacing={3} justify="center" alignItems="center"  direction={vw > 400 ? "row" : "column"} xs="12" style={{marginTop : "5vh"}}  >
          <Grid item >
          <Button color="secondary" variant="outlined" size="large" onClick={ e => ( window.open('mailto:' + help_email, 'emailWindow') )  } > <h4  style={{  "textTransform": "none",  marginTop : 0, marginBottom : 0 }}  > 
          {'Contact Sales'} </h4> </Button>
          </Grid>
          </Grid>

          </Grid>

        
      </Grid>

     </Grid>




    



  

      <Grid container style={{ backgroundColor: 'white'}} xs="12" >  
      
      <Grid container style={{ margin: ("10vh","10vw") }} align="left"  >

          <Grid xs="6" md="3" >
            <Typography style={{ marginBottom : "6px", marginTop : "14px"}}> Language </Typography>
            <Typography style={{ marginBottom : "6px"}} variant="body2" 
                        onClick={(e) => {this.setState({lpOpen : true})} } >
                        <Link color="inherit" children={lang_name} />
            </Typography>
          </Grid>
          <Grid xs="6" md="3" >
            <Typography style={{ marginBottom : "6px", marginTop : "14px"}} > Region </Typography>
            <Typography style={{ marginBottom : "5px"}} variant="body2" 
                        onClick={(e) => {this.setState({cpOpen : true})} } >
                        <Link color="inherit" children={country_name} />
            </Typography>
          </Grid>
          <Grid xs="6" md="3" >
            <Typography style={{ marginBottom : "6px", marginTop : "14px"}} > Support </Typography> 
            <Typography style={{ marginBottom : "5px"}} variant="body2" > 
            <Link color="inherit" href={"mailto:" + help_email}> Write to us  </Link>
            </Typography> 
            
            <Typography style={{ marginBottom : "5px"}} variant="body2" > 
            <Link color="inherit" href={"tel:" + contact_number} target='_blank' > Call Us  </Link>
            </Typography>
            <Typography style={{ marginBottom : "5px"}} variant="body2" > 
            <Link color="inherit" href={"mailto:" + help_email} > Feedback  </Link>
            </Typography>
          
          </Grid>
          <Grid xs="6" md="3" >
            <Typography style={{ marginBottom : "6px", marginTop : "14px"}} > Resources </Typography>
            <Typography style={{ marginBottom : "5px"}} variant="body2" > 
            <Link color="inherit"  children={p.t("Privacy",'/copyright/privacy/v1')} href={window.location.origin + '/privacy'} />
            </Typography>
            <Typography style={{ marginBottom : "5px"}} variant="body2" >
            <Link color="inherit"  children={p.t("Terms",'/copyright/terms/v1')} href={window.location.origin + '/terms'} />
            </Typography>

          </Grid>
          

      </Grid>
     </Grid>
   
      <AppBar position="static" align="center" variant="outlined"  elevation={0} style={{backgroundColor: 'rgba(237, 246, 252,0.8)'}}>
      <Toolbar>
          <Copyright p={p} />
      </Toolbar>
      </AppBar>
    </Container>
    {this.LanguagePicker()}
    {this.CountryPicker()}

    { this.state.cbOpen &&
     <AppBar position="fixed" style={{ bottom : 0, top : "auto", backgroundColor: 'rgba(0,0,0,0.9)' }} >
     <Toolbar >
        <h5 className={classes.title} style={{ margin : "1vh" }}  >
          <div> <p children={p.t(messageCookies,'/cookies/message/v1')}/> </div>
        </h5>
        <Button style={{ marginRight: "7vh"}}  color="secondary" variant="contained" size="small" onClick={ e => this.setState({cbOpen : false})  } > <h4  style={{ "textTransform": "none", color: "white", marginTop : 0, marginBottom : 0 }}  > OK </h4> </Button>  
     </Toolbar>
     </AppBar> }
      
    </div>
    </ThemeProvider>

    );
  }
}

const WebI = withStyles(useStyles)(WebW);

const Web = (props) => {
  return ( 
    <WebI matches={useMediaQuery(theme.breakpoints.up('sm'))} {...props} /> 
  );
}

export default Web